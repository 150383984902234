import { useState, useEffect } from "react";
import axios from "axios";

import Carrulla from "../images/comprar/Carrulla.png";
import Exito from "../images/comprar/Exito.png";
import Jumbo from "../images/comprar/Jumbo.png";
import SuperMaxi from "../images/comprar/SuperMaxi.png";
import Tottus from "../images/comprar/Tottus.png";
import Wong from "../images/comprar/Wong.png";

import Enalianzaconbox from "../components/Enalianzaconbox";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import Footer from "../components/footer";

function App() {
  const [showCountry, setCountry] = useState(0);

  const filterCountry = async (data) => {
    setCountry(data.target.value);
  };

  const fetchIpLocation = async () => {
    const result = await axios("https://ip.nf/me.json");
    console.log(result.data.ip.country);
  };
  fetchIpLocation();
  return (
    <>
      <Nav active={5} />
      <section className="nsdaf93ijsfsj">
        <div className="quesos-back">
          <BreadCrumb
            active={1}
            data={[
              { link: "/", title: "Inicio" },
              { link: "", title: "Dónde comprar" },
            ]}
          />
        </div>
        <div className="container">
          <h1 className="">¿Dónde comprar?</h1>
          <p style={{ marginBottom: 70 }}>
            La existencia de quesos está sujeta a la distribución de cada
            sucursal. Te recomendamos llamar y preguntar por el queso que buscas
            para asegurarte de que esté disponible.
          </p>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-6"></div>
            <div className="col-md-2">
              <select
                className="asfjsi3ie9asmbe form-control"
                placeholder="País"
                onChange={(e) => filterCountry(e)}
              >
                <option value="0">Todos los países</option>
                <option value="1">Colombia</option>
                <option value="2">Ecuador</option>
                <option value="3">Perú</option>
              </select>
            </div>
            <div className="col-md-4" style={{ textAlign: "right" }}></div>
          </div>
          <br />
          <br />
          <div className="">
            <div className="row">
              <div className="col-md-2"></div>
              {showCountry == 0 && (
                <div className="col-md-2">
                  <a
                    href="https://www.carulla.com/delicatessen-y-panaderia/quesos?initialMap=c&initialQuery=delicatessen-y-panaderia&order=OrderByPriceDESC&page=2"
                    target="_blank"
                  >
                    <img
                      src={Carrulla}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </a>
                </div>
              )}
              {showCountry == 0 && (
                <div className="col-md-2">
                  <a
                    href="https://www.exito.com/mercado/delicatessen/quesos"
                    target="_blank"
                  >
                    <img
                      src={Exito}
                      style={{ alignItems: "center", justifyContent: "center" }}
                      className=" "
                    />
                  </a>
                </div>
              )}

              {showCountry == 0 && (
                <div className="col-md-2">
                  <a
                    href="https://www.tiendasjumbo.co/supermercado/charcuteria/quesos-especializados"
                    target="_blank"
                  >
                    <img
                      src={Jumbo}
                      style={{ alignItems: "center", justifyContent: "center" }}
                      className=" "
                    />
                  </a>
                </div>
              )}
              {showCountry == 0 && (
                <div className="col-md-2">
                  <a href="https://www.supermaxi.com/" target="_blank">
                    <img
                      src={SuperMaxi}
                      style={{ alignItems: "center", justifyContent: "center" }}
                      className=" "
                    />
                  </a>
                </div>
              )}
            </div>
            <br />

            <div className="row" style={{ paddingTop: 10 }}>
              <div className="col-md-2" style={{ marginLeft: 0 }}></div>
              <div className="col-md-2"> </div>
              {showCountry == 0 && (
                <div className="col-md-2">
                  <a
                    href="https://www.tottus.com.pe/quesos-y-fiambres-4010004/c/"
                    target="_blank"
                  >
                    <img
                      src={Tottus}
                      style={{ alignItems: "center", justifyContent: "center" }}
                      className=" "
                    />
                  </a>
                </div>
              )}
              {showCountry == 0 && (
                <div className="col-md-2">
                  <a
                    href="https://www.wong.pe/lacteos/la-queseria?&filters=fq=B:740,fq=B:9779,fq=B:2000327,fq=B:2001265"
                    target="_blank"
                  >
                    <img
                      src={Wong}
                      style={{ alignItems: "center", justifyContent: "center" }}
                      className=" "
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col-md-2"></div>
            {showCountry == 1 && (
              <div className="col-md-2">
                <a
                  href="https://www.carulla.com/delicatessen-y-panaderia/quesos?initialMap=c&initialQuery=delicatessen-y-panaderia&order=OrderByPriceDESC&page=2"
                  target="_blank"
                >
                  <img
                    src={Carrulla}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className=" "
                  />
                </a>
              </div>
            )}
            {showCountry == 1 && (
              <div className="col-md-2">
                <a
                  href="https://www.exito.com/mercado/delicatessen/quesos"
                  target="_blank"
                >
                  <img
                    src={Exito}
                    style={{ alignItems: "center", justifyContent: "center" }}
                    className=" "
                  />
                </a>
              </div>
            )}

            {showCountry == 1 && (
              <div className="col-md-2">
                <a
                  href="https://www.tiendasjumbo.co/supermercado/charcuteria/quesos-especializados"
                  target="_blank"
                >
                  <img
                    src={Jumbo}
                    style={{ alignItems: "center", justifyContent: "center" }}
                    className=" "
                  />
                </a>
              </div>
            )}
            {showCountry == 2 && (
              <div className="col-md-2">
                <a href="https://www.supermaxi.com/" target="_blank">
                  <img
                    src={SuperMaxi}
                    style={{ alignItems: "center", justifyContent: "center" }}
                    className=" "
                  />
                </a>
              </div>
            )}
          </div>
          <br />

          <div className="row" style={{ paddingTop: 10 }}>
            <div className="col-md-2" style={{ marginLeft: 0 }}></div>
            <div className="col-md-2"> </div>
            {showCountry == 3 && (
              <div className="col-md-2">
                <a
                  href="https://www.tottus.com.pe/quesos-y-fiambres-4010004/c/"
                  target="_blank"
                >
                  <img
                    src={Tottus}
                    style={{ alignItems: "center", justifyContent: "center" }}
                    className=" "
                  />
                </a>
              </div>
            )}
            {showCountry == 3 && (
              <div className="col-md-2">
                <a
                  href="https://www.wong.pe/lacteos/la-queseria?&filters=fq=B:740,fq=B:9779,fq=B:2000327,fq=B:2001265"
                  target="_blank"
                >
                  <img
                    src={Wong}
                    style={{ alignItems: "center", justifyContent: "center" }}
                    className=" "
                  />
                </a>
              </div>
            )}
          </div>
        </div>

        <br />
        <br />
      </section>
      <Enalianzaconbox />
      <Footer />
    </>
  );
}

export default App;
