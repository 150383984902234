import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import Enalianzaconbox from "../components/Enalianzaconbox";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import RecetasconestequesoCard from "../components/RecetasconestequesoCard";
import Footer from "../components/footer";
import left from "../images/arwleft.svg";
import right from "../images/arwright.svg";

function App() {
  const [data, setData] = useState([]);
  const [quesos, setQuesos] = useState([]);

  const url = "http://localhost:3000";

  console.log(url);

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [filter, setFilter] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const nextPage = async () => {
    if (currentPage < maxPages) {
      setStart(end);
      setEnd(end + itemsPerPage);
      setCurrentPage(currentPage + 1);
    }
  };

  const backPage = async () => {
    if (currentPage > 1) {
      setStart(start - itemsPerPage);
      setEnd(start);
      setCurrentPage(currentPage - 1);
    }
  };

  const fetchData = async () => {
    const result = await axios(url_base + "/recetas");
    console.log(result.data);
    setData(result.data);
    setMaxPages(Math.round(result.data.length / itemsPerPage));
    setEnd(itemsPerPage);
  };

  const getQuesos = async () => {
    const result = await axios(url_base + "/quesos");
    console.log(result.data);
    setQuesos(result.data);
  };

  const filterCheese = async (data) => {
    if (data.target.value == "todos") {
      fetchData();
    } else {
      const result = await axios(
        url_base + "/recetas?_where[0][id]=" + data.target.value
      );
      setData(result.data);
      setMaxPages(Math.round(result.data.length / itemsPerPage));
      console.log(data.target.value);
      console.log(result.data);
    }
  };

  useEffect(() => {
    getQuesos();
    fetchData();
  }, []);

  return (
    <>
      <Nav active={0} />
      <section className="nsdaf93ijsfsj">
        <div className="quesos-back">
          <BreadCrumb
            active={1}
            data={[
              { link: "/", title: "Inicio" },
              { link: "", title: "Aviso de privacidad" },
            ]}
          />
        </div>
        <div className="container">
          <div class="wpb_wrapper" style={{textAlign: 'center'}}>
            <h1>
              <span>
                <b>Términos y condiciones de uso del sitio Quesos de Europa</b>
              </span>
            </h1>
            <p>
              <span>
                  Es posible que otros sitios web de Quesos de Europa contengan
                  términos y condiciones diferentes a estos. Al navegar por los
                  sitios web de Quesos de Europa, por favor revise los términos
                  y condiciones de cada sitio web que visite y no asuma que
                  estos términos y condiciones se aplican a todos los sitios web
                  de Quesos de Europa.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Alcance y uso</b>
            </p>
            <p>
              <span>
                  Al acceder Quesos de Europa [quesosdeeuropa.com] usted acepta
                  estos términos y condiciones. Asegúrese de leerlos atentamente
              </span>
              <span>. </span>
            </p>
            <p>
              <span>
                  Si usted no está de acuerdo con estos Términos y Condiciones,
                  no use el sitio.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Términos y condiciones</b>
            </p>
            <p>
              <span>
                  Este sitio web contiene material que incluye textos, imágenes
                  y sonidos, los cuales están protegidos por derecho de autor
                  y/u otros derechos de propiedad intelectual.&nbsp; Todos los
                  derechos de autor y otros derechos de propiedad intelectual, o
                  son propiedad de Quesos de Europa o se ha obtenido la licencia
                  de uso del propietario o propietarios de esos derechos para
                  que el material pueda ser usado como parte de este sitio web.
              </span>
            </p>
            <p>
              <span>
                  Usted puede acceder a cualquier parte de este sitio web e
                  imprimir una copia de cualquiera de sus páginas para su propia
                  referencia personal, sin nuestro consentimiento por escrito.
              </span>
            </p>
            <p>
              <span>
                  Usted no está autorizado a copiar o modificar el sitio, así
                  como ninguna parte de este ni nuestras marcas comerciales de
                  ningún modo. El sitio y todas las marcas comerciales, los
                  derechos de autor y demás derechos de propiedad intelectual
                  relacionados son propiedad de Quesos de Europa.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Confidencialidad</b>
            </p>
            <p>
              <span>
                  Si usted decide contactar a Quesos de Europa con cualquier
                  información, retroalimentación, datos, preguntas, comentarios
                  o sugerencias relacionados con el contenido del sitio, se
                  considerará que el contenido de la respuesta no es
                  confidencial y Quesos de Europa será libre de reproducir,
                  utilizar, divulgar y distribuir la información a terceros.
                  Además, al mandar un comentario o mensaje a Quesos de Europa,
                  usted acepta otorgar a Quesos de Europa todos los derechos de
                  autor de sus preguntas,
              </span>{" "}
              <span>
                  comentarios o sugerencias y acepta que Quesos de Europa es
                  libre de usar el contenido de su mensaje sin límite alguno.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Delimitación de responsabilidades</b>
            </p>
            <p>
              <span>
                  Es posible que Quesos de Europa [quesosdeeuropa.com] incluya
                  enlaces a sitios web externos, es decir, que no pertenezcan a
                  Quesos de Europa. Se proporcionan estos enlaces para ayudarle
                  a encontrar sitios web relevantes, servicios y/o productos que
                  puedan ser de su interés. Es su responsabilidad decidir si
                  cualquier servicio y/o producto disponible en alguno de esos
                  sitios es de utilidad para sus objetivos. Quesos de Europa no
                  es responsable por los propietarios u operadores de esos
                  sitios web, por cualquier producto o servicio que provean o
                  por los contenidos publicados. Asimismo, no proporciona o
                  entra en cualquier condición, garantía u otros términos o
                  representaciones relacionadas con ellos, ni acepta cualquier
                  responsabilidad que resulte de quejas respecto a que el
                  contenido de esos sitios infringe los derechos de propiedad
                  intelectual de cualquier tercero.
              </span>
            </p>
            <p>
              <span>
                Toda información o consejo proporcionado por el sitio web
                quesosdeeuropa.com tiene como propósito orientar al usuario. No
                dependas de él para tomar cualquier decisión. Quesos de Europa
                trata de asegurar que toda información proporcionada como parte
                de este sitio web sea correcta al momento de ser incluida, más
                no garantiza la exactitud de dicha información. Quesos de Europa
                no es responsable por cualquier acción que usted pueda realizar
                como resultado de tomar en cuenta dicha información o consejo, o
                por cualquier pérdida o daño sufrido que resulte de la
                realización de dicha acción.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
                Cambios en términos y condiciones, así como en la delimitación
                de responsabilidades
            </p>
            <p>
              <span>
                Es posible que Quesos de Europa cambie los términos y
                condiciones, así como la delimitación de responsabilidades. Al
                navegar en este sitio web usted acepta que está sujeto a los
                términos y condiciones y a la delimitación de responsabilidades
                vigentes, por lo que debe revisarlos cada vez que vuelva a
                visitar el sitio.
              </span>
            </p>
            <p>
              <span>
                Este sitio web puede permitir que usted se enlace o se conecte
                para usar funciones de aplicaciones, servicios o sitios de
                terceros que no sean propiedad de o estén controladas por Quesos
                de Europa. Las aplicaciones de terceros están sujetas a los
                términos y condiciones del proveedor tercero relevante. Quesos
                de Europa
              </span>{" "}
              <span>
                no tiene control sobre, ni es responsable por, ningún asunto
                relacionado con sus tratos o transacciones con tales terceros ni
                sobre ningún contenido, contenido distribuido, aplicación,
                servicio, sitio, anuncio, enlace, política de privacidad o
                práctica de terceros. Es su responsabilidad consultar las
                políticas de privacidad y los términos y condiciones de servicio
                y uso de todos los sitios de terceros que visite antes de
                descargar o usar tales aplicaciones de terceros.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Cambios en el sitio web</b>
            </p>
            <p>
              <span>
                Quesos de Europa puede cambiar el formato y contenido de este
                sitio web en cualquier momento. Asimismo, puede suspender la
                operación de este para trabajos de respaldo o mantenimiento, con
                el propósito de actualizar su contenido o por cualquier otra
                razón.
              </span>
            </p>
            <p>
              <span>Quesos de Europa</span>{" "}
              <span>
                se reserva el derecho de terminar el acceso a este sitio web en
                cualquier momento y sin previo aviso.
              </span>
            </p>
            <p>
              <span>
                Los Términos y Condiciones y la relación entre usted y nosotros
                serán regidos por las leyes de México o Colombia según sea el
                caso.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Uso de Cookies y herramientas tecnológicas</b>
            </p>
            <p>
              <span>
                Nuestro sitio web utiliza herramientas de cookies para mejorar
                la experiencia del usuario en nuestras páginas web, por lo que,
                al navegar en el mismo, usted consiente el uso de las mismas,
                salvo que utilice configuraciones y herramientas apropiadas en
                sus propios navegadores que inhabiliten el uso de las mismas.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>Cookies de medición y analíticas</b>
            </p>
            <p>
              <span>
                El uso de las cookies analíticas y de medición en nuestro sitio
                web tiene como finalidad única mejorar la experiencia del
                usuario en la red mediante la medición del tráfico en nuestras
                webs, así como la obtención y análisis de datos estadísticos de
                los usuarios de forma anónima, a través de las siguientes
                funciones:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Permite la identificación de los dispositivos y navegadores
                  que se utilizan por el usuario para navegar en la web, sin
                  embargo, no utilizamos ninguna herramienta de rastreo que
                  permita identificar al usuario.
                </span>
              </li>
              <li>
                <span>
                  Permite contabilizar de forma aproximada el número de
                  visitantes y su tendencia en el tiempo.
                </span>
              </li>
              <li>
                <span>
                  Nos permite identificar los contenidos más visitados a través
                  de los dispositivos o navegadores desde los que se accede.
                  Dicha identificación se realiza de forma anónima.
                </span>
              </li>
              <li>
                <span>
                  Nos permite conocer si los dispositivos desde los que se está
                  accediendo a nuestro sitio web son nuevos o repiten visita.
                </span>
              </li>
              <li>
                <span>
                  Nos permite conocer los gustos y preferencias de los usuarios
                  de forma general y anónima, mediante la obtención de datos
                  estadísticos y presentar información que consideramos de
                  interés para los mismos.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                Esta página web utiliza como herramienta “Google Analytics”, un
                servicio analítico de medición de tráfico en la web prestado por
                Google, Inc.
              </span>
            </p>
            <p>
              <span>
                La información que genera la cookie acerca de su uso de la web
                (incluyendo la dirección IP del dispositivo desde el que se
                accede) será directamente recabada, transmitida y archivada por
                Google en los servidores ubicados en Estados Unidos.
              </span>
            </p>
            <p>
              <span>
                Google podrá transmitir dicha información a terceros de
                conformidad con sus propias políticas de privacidad y su
                legislación local, por lo que al navegar en el sitio web, usted
                consiente y acepta, en conjunto con las nuestras, las políticas
                de privacidad de Google, en particular las referentes a la
                herramienta de “Google Analytics”.
              </span>
            </p>
            <p>
              <span>
                Usted podrá encontrar más información respecto a la política de
                privacidad y seguridad de los datos de “Google analytics”
                en:&nbsp;
              </span>
              <a href="http://www.google.com/analytics/learn/privacy.html">
                <span>
                  http://www.google.com/analytics/learn/privacy.html
                </span>
              </a>
            </p>
          </div>
        </div>
        <br />
        <br />
      </section>
      <Enalianzaconbox />
      <Footer />
    </>
  );
}

export default App;
