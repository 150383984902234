import React from "react";
import { Dropdown } from "react-bootstrap";
import { ImCross } from "react-icons/im";

import ig from "../images/IG.svg";
import fb from "../images/fb.svg";
import tw from "../images/tw.svg";

export default ({ handleclose, active }) => {
  return (
    <div className="bold-lat">
      <ul className="asdfsadojfisf-sdff">
        <li>
          {" "}
          <a onClick={() => handleclose()}>
            <ImCross color="white" />
          </a>
        </li>
        <li>
          {" "}
          <a href="/quesos" className={active == "1" ? "menu-li" : ""}>
            Quesos{" "}
          </a>
        </li>
        <li>
          {" "}
          <a
            href="/cultura-y-quesos"
            className={active == "2" ? "menu-li" : ""}
          >
            Cultura y queso
          </a>
        </li>
        <li>
          {" "}
          <a href="/recetas" className={active == "3" ? "menu-li" : ""}>
            Recetas
          </a>
        </li>
        <li>
          {" "}
          <a href="/eventos" className={active == "4" ? "menu-li" : ""}>
            eventos
          </a>
        </li>
        <li>
          {" "}
          <a href="/donde-comprar" className={active == "5" ? "menu-li" : ""}>
            ¿dónde comprar?
          </a>
        </li>
      </ul>
      <div className="sam039ojascf">
        <div className="asdfb382ashndsf"></div>
        <div className="d-flex mt-2 menu-follow-container" style={{justifyContent: 'space-around'}}>
          <p className="menu-follow">Síguenos:</p>
          <p>
            <a
              href="https://www.facebook.com/QuesosDeEuropaAndino/"
              target="_blank"
            >
              <img src={fb} className="share-float-img" />
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/quesosdeeuropa/" target="_blank">
              <img src={ig} className="share-float-img" />
            </a>
          </p>
          <p>
            <a href="https://twitter.com/quesosdeeuropa" target="_blank">
              <img src={tw} className="share-float-img" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
