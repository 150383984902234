import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import img1 from "../images/img1.png";
import img11 from "../images/img11.png";
import NuestrosCard from "../components/NuestrosQuesosCard";
import { nuestrosList2 } from "../utilees/nuestrosList";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import { culturayQuesoList } from "../utilees/CulturayQuesoList";
import img17 from "../images/img17.png";
import img18 from "../images/img18.png";
import img19 from "../images/img19.png";
import img20 from "../images/img20.png";
import img21 from "../images/img21.png";
import img22 from "../images/img22.png";
import img27 from "../images/img27.png";
import logo from "../images/logo.png";
import Enalianzaconbox from "../components/Enalianzaconbox";
import { AiOutlineSearch } from "react-icons/ai";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import Footer from "../components/footer";
import ig from "../images/IG.svg";
import fb from "../images/fb.svg";
import tw from "../images/tw.svg";

function App() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [favorites, setFavorites] = useState([]);

  const url = "http://localhost:3000";

  console.log(url);

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";
  const ip_url = "https://ip.nf/me.json";

  const getIP = async () => {
    await axios
      .get(url)
      .then((response) => {
        console.log("response");
        console.log(response);
        console.log("response");
        // Obtenemos los datos
      })
      .catch((e) => {
        // Capturamos los errores
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const result = await axios(url_base + "/quesos?favorito_andino=false");
      console.log(result.data);
      setData(result.data.sort(() => Math.random() - 0.5));
    };
    const fetchFavorites = async () => {
      const result = await axios(url_base + "/quesos?favorito_andino=true");
      console.log(result.data);
      setFavorites(result.data.sort(() => Math.random() - 0.5));
    };
    getIP();
    fetchFavorites();
    fetchData();
  }, []);

  return (
    <>
      <Nav active={1} />
      <section className="nsdaf93ijsfsj">
        <div className="quesos-back">
          <BreadCrumb
            active={1}
            data={[
              { link: "", title: "Inicio" },
              { link: "", title: "Quesos" },
            ]}
          />
        </div>
        <div className="container">
          <h1> Nuestros Quesos</h1>
          <p>
            El mundo de los quesos europeos es inmenso y delicioso, conócelos y
            enamórate. Te invitamos a incluirlos en las opciones del día a día y
            usarlos incluso para preparar platillos locales, logrando una nueva
            mezcla de sabores.
          </p>
          <div className="row">
            {favorites.slice(0, 5).map((x) => (
              <div className="col-md-3 col-6">
                <NuestrosCard
                  img={url_base + x.imgqueso.url}
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}

            {data.slice(0, 1).map((x) => (
              <div className="col-md-3 col-6">
                <NuestrosCard
                  img={url_base + x.imgqueso.url}
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
            <div className="col-md-6 mb-5 pb-5">
              <div className="comprar-container">
                <div className="comprar-container-header">
                  ¿Quieres comprar?
                </div>
                <div className="comprar-container-subheader">
                  Consulta las tiendas donde puedes encontrar queso, crema y
                  mantequilla de Europa.
                </div>
                <div
                  style={{
                    marginTop: 30,
                    textAlign: "left",
                    paddingLeft: "15%",
                  }}
                >
                  <button className="btn asfiejq3mdasadf3-asfd">
                    Encontrar tienda
                  </button>
                </div>
              </div>
            </div>

            {data.slice(1, data.length).map((x) => (
              <div className="col-md-3 col-6">
                <NuestrosCard
                  img={url_base + x.imgqueso.url}
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Enalianzaconbox />
      <Footer />
      <div className="fixedScrollbtn">
        <p>
          <img src={ig} />
        </p>
        <p>
          <img src={fb} />
        </p>
        <p>
          <img src={ig} />
        </p>
      </div>
    </>
  );
}

export default App;
