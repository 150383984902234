import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import img24 from "../images/img24.png";
import img23 from "../images/img23.svg";
import gusto from "../images/ico_gusto.svg";
import olfato from "../images/icon_Olfato.svg";
import tacto from "../images/ico_Tacto.svg";
import vista from "../images/ico_Vista.svg";
import img32 from "../images/img32.png";
import img33 from "../images/img33.png";
import img16 from "../images/img16.png";
import img13 from "../images/img13.png";
import img34 from "../images/img34.png";
import NuestrosCard from "../components/NuestrosQuesosCard";
import { nuestrosList } from "../utilees/nuestrosList";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import { culturayQuesoList } from "../utilees/CulturayQuesoList";
import RecetasconestequesoCard from "../components/RecetasconestequesoCard";
import Enalianzaconbox from "../components/Enalianzaconbox";
import Nav from "../components/navbar";
import BreadCrumb from "../components/breadcrum";
import Footer from "../components/footer";

function App() {
  const params = useParams();

  console.log(params.slug);

  const [cheese, setCheese] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const [data, setData] = useState([]);
  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getCheese = async () => {
      const result = await axios(
        "https://api.quesosdeeuropa.com/quesos?slug=" + params.slug
      );
      console.log(result.data[0]);
      setCheese(result.data);
    };
    const fetchData = async () => {
      const result = await axios(url_base + "/quesos");
      setData(result.data);
    };
    const fetchRecetas = async (data) => {
      const result = await axios(
        url_base + "/recetas?_where[quesos.slug]=" + data
      );
      console.log(result.data);
      setRecetas(result.data);
    };
    fetchRecetas(params.slug);
    getCheese();
    fetchData();
    fetchRecetas();
  }, []);

  const onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: "",
        errored: true,
      });
    }
  };

  return (
    <>
      <div>
        {data.map((x) => (
          <Helmet>
            <title>{x.nombre}</title>
            <meta
              name="description"
              content={x.descripcion.substring(0, 100)}
            />
          </Helmet>
        ))}
      </div>
      <Nav />
      <section className="page-2">
        <br />
        <br />
        <div className="pt-1">
          {cheese.map((x) => (
            <BreadCrumb
              active={2}
              data={[
                { link: "", title: "Inicio" },
                { link: "", title: "Quesos" },
                { link: "", title: x.nombre },
              ]}
            />
          ))}
          <br />
        </div>
        {cheese.map((x) => (
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="">
                <img
                  src={url_base + x.imgqueso.url}
                  onError={() => onError()}
                  className="asfdkfj3wwas"
                />
              </div>
              <div className="saasc3rw3vfbfh">
                <div>
                  <h1> {x.nombre}</h1>
                  <p>{x.descripcion}</p>
                </div>
                <div className="d-flex ">
                  <div className="">
                    <img src={img23} />{" "}
                  </div>
                  <div className="sadasd33vb">
                    <p className="m-0">
                      <span>País de origen:</span> {x.pais}
                    </p>
                    <p>
                      <span>Región:</span> {x.pais}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="asdfji329nmc">
              <div>
                <h1>Especificaciones</h1>
                <div className="d-flex flex-wrap justify-content-between dasfji93">
                  <div>
                    <h2>Tipo</h2>
                    <p> {x.tipo} </p>
                  </div>
                  <div>
                    <h2>Hecho de:</h2>
                    <p> {x.hecho}</p>
                  </div>
                  <div>
                    <h2>Grasa</h2>
                    <p> {x.grasa}</p>
                  </div>
                </div>
              </div>
              <div className="asdsaih8i2mzn2">
                <h1>Características</h1>
                <p className="text-center" style={{ maxWidth: "100%" }}>
                  {x.caracteristicas}
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={vista} className="w-100" />
                      </div>
                      <div>
                        <h2 className="">Vista</h2>
                      </div>
                    </div>
                    <p style={{ marginTop: 10 }}>{x.vista}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={gusto} className="w-100" />
                      </div>
                      <div>
                        <h2 className="">Gusto</h2>
                      </div>
                    </div>
                    <p style={{ marginTop: 10 }}>{x.gusto}</p>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={olfato} className="w-100" />
                      </div>
                      <div>
                        <h2 className="">Olfato</h2>
                      </div>
                    </div>
                    <p style={{ marginTop: 10 }}>{x.olfato}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={tacto} className="w-100" />
                      </div>
                      <div>
                        <h2 className="">Tacto</h2>
                      </div>
                    </div>
                    <p style={{ marginTop: 10 }}>{x.tacto}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      <section className="sadans8d923nsd">
        {cheese.map((x) => (
          <div className="container">
            <div className="sadiasndw29zc">
              <div className="row ">
                <div className="col-md-6">
                  <h1>Cómo cortarlo</h1>
                  <p>
                    Por su gran tamaño, es común encontrar el brie en cortes
                    triangulares en forma de cuña. A la hora de cortarlo, para
                    asegurar que todas las porciones tengan un poco de corteza,
                    el corte debería ser como sigue:
                  </p>
                  <p>
                    <span>1.</span> Primero la punta
                  </p>
                  <p>
                    <span>2.</span> Después un corte en diagonal
                  </p>
                  <p>
                    <span>3.</span> Y luego uno en perpendicular hasta el final
                    de la corteza
                  </p>
                </div>
                <div className="col-md-6">
                  <img
                    class="cortar-img"
                    src={url_base + x.imagencortarlo.url}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      <section>
        {cheese.map((x) => (
          <div className="">
            <div className="row">
              <div className="col-md-6 bg-black background-guardado">
                <div className="asdjfkj39cnakwe">
                  <h1>Cómo guardarlo</h1>
                  <p>{x.guardado}</p>
                </div>
              </div>
              <div className="col-md-6 bg-theme background-queso">
                <div className="asdjfkj39cnakwe">
                  <h1>Maridaje</h1>
                  <p>{x.maridaje}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
      <section className="asd82hasd0asz">
        <div className="container">
          <h1>Recetas con este queso</h1>
          {cheese.map((x) => (
            <p></p>
          ))}
          <div className="row">
            {recetas.slice(0, 3).map((x) => (
              <div className="col-md-4">
                <RecetasconestequesoCard
                  img={url_base + x.imagen.url}
                  buttonTitle="Continuar leyendo"
                  title={x.nombre}
                  description={x.descripcion}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div style={{ marginTop: 100 }}>
          <div className="asdfasdif38ahsnbw">
            <h1>¿Quieres comprar?</h1>
            <p>
              Encuentra las tiendas en donde puedes encontrar nuestros
              productos.
            </p>
            <button className="btn">Encontrar tienda</button>
          </div>
        </div>
      </section>
      <section className="nsdaf93ijsfsj" style={{ paddingBottom: 0 }}>
        <div className="asdn38hasn3zlc">
          <div className="container">
            <h1> Otros Quesos</h1>
            <div className="row">
              {data.slice(0, 4).map((x) => (
                <div className="col-md-3 col-6">
                  <NuestrosCard
                    img={url_base + x.imgqueso.url}
                    title={x.nombre}
                    description={x.descripcion}
                    slug={x.slug}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <section className="Recetas mt-5 mb-5" style={{ textAlign: "left" }}>
          <div className="container">
            <div className="row">
              <h1 className="text-center mb-5">Artículos relacionados</h1>
              <div className="col-md-6 mb-5 pb-5">
                <CulturayQuesoCard
                  title="Navidad a la francesa. Costumbres y quesos que se suelen comer en estas fechas"
                  description="Se acercan las festividades decembrinas y en todo el mundo es un momento para celebrar en familia, así como de…"
                  hash={["#Cultura", "#Historia"]}
                  img={img16}
                  readtime="Lectura de 3 minutos"
                />
              </div>
              <div className="col-md-6 mb-5 pb-5">
                <CulturayQuesoCard
                  title="Navidad a la francesa. Costumbres y quesos que se suelen comer en estas fechas"
                  description="Se acercan las festividades decembrinas y en todo el mundo es un momento para celebrar en familia, así como de…"
                  hash={["#Cultura", "#Historia"]}
                  img={img16}
                  readtime="Lectura de 3 minutos"
                />
              </div>
            </div>
          </div>
        </section>
      </section>
      <div style={{ marginTop: -100 }}>
        <Enalianzaconbox />
      </div>
      <Footer />
    </>
  );
}

export default App;
