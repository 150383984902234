import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import Footer from "../components/footer";
import img1 from "../images/img1.png";
import img11 from "../images/img11.png";
import NuestrosCard from "../components/NuestrosQuesosCard";
import { nuestrosList2 } from "../utilees/nuestrosList";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import { culturayQuesoList } from "../utilees/CulturayQuesoList";
import img17 from "../images/img17.png";
import img18 from "../images/img18.png";
import img19 from "../images/img19.png";
import img20 from "../images/img20.png";
import img21 from "../images/img21.png";
import img22 from "../images/img22.png";
import img27 from "../images/img27.png";
import img16 from "../images/img16.png";
import img13 from "../images/img13.png";
import img33 from "../images/img34.png";
import img34 from "../images/img34.png";

import logo from "../images/logo.png";
import Enalianzaconbox from "../components/Enalianzaconbox";
import { AiOutlineSearch } from "react-icons/ai";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import RecetasconestequesoCard from "../components/RecetasconestequesoCard";

function App() {
  const [data, setData] = useState([]);
  const url = "http://localhost:3000";

  console.log(url);

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const result = await axios(url_base + "/eventos");
      console.log(result.data);
      setData(result.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Nav active={4} />
      <section className="Próximoseventos">
        <div className="container">
          <div className="quesos-back">
            <br />
            <br />
            <BreadCrumb
              active={1}
              data={[
                { link: "", title: "Inicio" },
                { link: "", title: "Eventos" },
              ]}
            />
          </div>
          <h1> Próximos eventos</h1>
          <br />
          <div style={{textAlign: 'center'}}>
            <p> Pronto compartiremos nuevas experiencias</p>
          </div>
          <div className="row">
            {data.map((x) => (
              <div className="col-md-4">
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/evento/${x.slug}`}
                >
                  <div
                    className="asfjidfjasdkoasp3"
                    style={{
                      "--img": `url("${url_base + x.imagen.url}")`,
                      textAlign: "center",
                    }}
                  >
                    <p>{x.cuando}</p>
                    <p className="h-evento">{x.nombre}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Enalianzaconbox />
      <Footer />
    </>
  );
}

export default App;
