import React from "react";

import two from "../images/aliados/entremont_02 1.png";
import four from "../images/aliados/537_ERMITAGE 1.png";
import five from "../images/aliados/Logo_Paysan_Breton 1.png";
import six from "../images/aliados/download 1.png";
import eight from "../images/aliados/logo-fromager-RVB 1.png";
import nine from "../images/aliados/President-Logo 1.png";
import ten from "../images/aliados/unnamed 2.png";
import eleven from "../images/aliados/IsignySteMere.png";

export default () => {
  return (
    <section className="Enalianzacon">
      <div className="container">
        <h1 className="blue-text">En alianza con</h1>
        <div className="sadi3jsadjzczxcs">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={two}
                className="img-aliado "
              />
            </div>
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={six}
                className="img-aliado "
              />
            </div>
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={nine}
                className="img-aliado "
              />
            </div>
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={ten}
                className="img-aliado "
              />
            </div>
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={five}
                className="img-aliado "
              />
            </div>
            <div className="asdj83ieawd2">
              <img
                style={{ alignItems: "center", justifyContent: "center" }}
                src={eleven}
                className="img-aliado "
              />
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-center"></div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  );
};
