import { useState } from "react";
import axios from "axios";
import validator from "validator";

import logo from "../images/logo.png";
import { BsFillHeartFill } from "react-icons/bs";
import {
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrYoutube,
} from "react-icons/gr";
import { Link } from "react-router-dom";

const url_base = "https://api.quesosdeeuropa.com";

export default () => {
  const [email, setEmail] = useState("");
  const [sector, setSector] = useState("");
  const [suscrito, setSuscrito] = useState(false);

  const fetchData = async () => {
    if (validator.isEmail(email) && sector != "") {
      const new_user = {
        user: email,
        sector: sector,
      };
      axios
        .post(url_base + "/newsletters", new_user)
        .then((response) => setSuscrito(true));
    } else {
      alert("Email no valido");
    }
  };

  return (
    <div className="askdin3e0asddk23">
      <div className="container">
        <div className="d-flex justify-content-center">
          <img className="asfn3iisf3sadk2" src={logo} />
        </div>
        <div className="b-1asd22"></div>
        <div className="asdfnu328scnsmw">
          {suscrito != true && (
            <div className="asdni83en23sd">
              <h1>
                <BsFillHeartFill
                  color="#EB5919"
                  style={{ marginTop: -10, marginRight: 10 }}
                />{" "}
                Mantente informado
              </h1>
              <p>
                ¿Eres un profesional o experto en quesos? Recibe las últimas
                noticias de Quesos de Europa, acerca de eventos, recetas o
                artículos de interés
              </p>
              <input
                placeholder="Correo electrónico"
                className="asfjsi3ie9asmbe form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
              <select
                className="asfjsi3ie9asmbe form-control"
                placeholder="Sector"
                style={{ color: "gray" }}
                onChange={(e) => setSector(e.target.value)}
              >
                <option value="" style={{ color: "gray" }}>
                  Sector
                </option>
                <option value="Productor" style={{ color: "gray" }}>
                  Productor
                </option>
                <option value="Importador/Exportador" style={{ color: "gray" }}>
                  Importador/Exportador
                </option>
                <option value="Distribuidor" style={{ color: "gray" }}>
                  Distribuidor
                </option>
                <option value="Chef" style={{ color: "gray" }}>
                  Chef
                </option>
                <option value="Estudiante de cocina" style={{ color: "gray" }}>
                  Estudiante de cocina
                </option>
                <option value="Amante de los quesos" style={{ color: "gray" }}>
                  Amante de los quesos
                </option>
              </select>
              <button
                className="btn asfiejq3mdasadf3-asfd"
                onClick={() => fetchData()}
              >
                Enviar
              </button>
            </div>
          )}
          {suscrito != false && (
            <div className="asdni83en23sd">
              <h1>
                <BsFillHeartFill
                  color="#EB5919"
                  style={{ marginTop: -10, marginRight: 10 }}
                />{" "}
                ¡Te has suscrito al newsletter!
              </h1>
              <p>
                {email} Recibirás las últimas noticias de Quesos de Europa,
                acerca de eventos, recetas o artículos de interés.
              </p>
            </div>
          )}
          <div className="asdni83en23sd">
            <h1>Productos</h1>
            <a href="/quesos">Quesos</a>
          </div>
          <div className="asdni83en23sd">
            <h1>Editorial</h1>
            <a href="/eventos">eventos</a>
            <a href="/cultura-y-quesos">Cultura y Queso</a>
            <a href="/recetas">Recetas</a>
          </div>
          <div className="asdni83en23sd">
            <h1>Links de interés</h1>
            <a href="/donde-comprar">¿dónde comprar?</a>
            <div className="d-flex justify-content-between mt-2 social-align">
              <div className="asdfj9w3ie0awj3n">
                <a
                  href="https://www.facebook.com/QuesosDeEuropaAndino/"
                  target="_blank"
                >
                  <GrFacebookOption color="white" fontSize={18} />
                </a>
              </div>
              <div className="asdfj9w3ie0awj3n">
                <a
                  href="https://www.instagram.com/quesosdeeuropa/"
                  target="_blank"
                >
                  <GrInstagram color="white" fontSize={18} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex footer-logos align-items-end flex-wrap">
          <div className="dansi2as0e"></div>
          <div>
            <p className="asdfop23leas-d">
              El contenido de esta publicidad representa el punto de vista del
              autor únicamente y es de su entera responsabilidad. La Unión
              Europea no es responsable del uso que pueda dársele a la
              información aquí contenida.
            </p>
          </div>
          <div className="dansi2as0e-1"></div>
        </div>
        <div className="d-flex justify-content-center">
          <p className="asmdi21mamd12" style={{ lineHeight: "120%" }}>
            2021 Quesos de Europa. Todos los derechos reservados.
            <span>
              <a
                href="terminos-y-condiciones"
                className="white no-underline"
                style={{ color: "white" }}
              >
                Términos y Condiciones
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
