import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import Enalianzaconbox from "../components/Enalianzaconbox";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import RecetasconestequesoCard from "../components/RecetasconestequesoCard";
import Footer from "../components/footer";
import left from "../images/arwleft.svg";
import right from "../images/arwright.svg";

function App() {
  const [data, setData] = useState([]);
  const [quesos, setQuesos] = useState([]);

  const url = "http://localhost:3000";

  console.log(url);

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [filter, setFilter] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const nextPage = async () => {
    if (currentPage < maxPages) {
      setStart(end);
      setEnd(end + itemsPerPage);
      setCurrentPage(currentPage + 1);
    }
  };

  const backPage = async () => {
    if (currentPage > 1) {
      setStart(start - itemsPerPage);
      setEnd(start);
      setCurrentPage(currentPage - 1);
    }
  };

  const fetchData = async () => {
    const result = await axios(url_base + "/recetas?quesos_gt=0");
    console.log(result.data);
    setData(result.data.reverse());
    setMaxPages(Math.round(result.data.length / itemsPerPage));
    setEnd(itemsPerPage);
  };

  const getQuesos = async () => {
    const result = await axios(url_base + "/quesos");
    setQuesos(result.data);
  };

  const filterCheese = async (data) => {
    if (data.target.value == "todos") {
      fetchData();
    } else {
      const result = await axios(
        url_base + "/recetas?_where[quesos.id]=" + data.target.value
      );
      setData(result.data);
      setMaxPages(Math.round(result.data.length / itemsPerPage));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getQuesos();
    fetchData();
  }, []);

  return (
    <>
      <Nav active={3} />
      <section className="nsdaf93ijsfsj">
        <div className="quesos-back">
          <BreadCrumb
            active={1}
            data={[
              { link: "/", title: "Inicio" },
              { link: "", title: "Recetas" },
            ]}
          />
        </div>
        <div className="container">
          <h1>Recetas</h1>
          <p style={{ marginBottom: 70 }}>
           
          </p>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <select
                className="input-filter form-control"
                placeholder="Quesos"
                onChange={(e) => filterCheese(e)}
              >
                <option value="todos">Todos los quesos</option>
                {quesos.map((x) => (
                  <option value={x.id}>{x.nombre}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            {data.slice(start, end).map((x) => (
              <div className="col-md-4">
                <RecetasconestequesoCard
                  img={url_base + x.imagen.url}
                  buttonTitle="Continuar leyendo"
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div>
                <Link onClick={() => nextPage()}>
                  <button
                    className="btn buton-recipes  m-auto"
                  >
                    Siguiente
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-4" style={{textAlign:'right'}}>
              <div
                className="d-flex next-container"
              >
                <div
                  style={{
                    display: "block ruby",
                  }}
                >
                  <Link onClick={() => backPage()}>
                    <button
                      style={{ height: 40, width: 40 }}
                      className="btn asjfioj23adsd m-auto"
                    >
                      <img src={left}></img>
                    </button>
                  </Link>
                  <input
                    onChange={(e) => setPage(e.target.value)}
                    value={currentPage}
                    placeholder="1"
                    style={{
                      width: 40,
                      height: 40,
                      textAlign: "center",
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />
                  <Link onClick={() => nextPage()}>
                    <button
                      style={{ height: 40, width: 40 }}
                      className="btn asjfioj23adsd m-auto"
                    >
                      <img src={right}></img>
                    </button>
                  </Link>
                  <div style={{ marginLeft: "3%" }}>de {maxPages}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
      <Enalianzaconbox />
      <Footer />
    </>
  );
}

export default App;
