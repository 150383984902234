import React from "react";
import { Link } from "react-router-dom";

export default ({ img, title, description, buttonTitle, slug }) => {
  return (
    <div className="sadfjkeui34bxzc" style={{textAlign:"left", marginTop:10}}>
      <Link style={{ textDecoration: "none" }} to={`/recetas/${slug}`}>
        <img src={img} />
      </Link>
      <div className="" style={{ marginLeft: 10, marginRight: 10 }}>
        <Link style={{ textDecoration: "none" }} to={`/recetas/${slug}`}>
          <div style={{ }}>
            <h1> {title}</h1>
          </div>
        </Link>
        <Link style={{ textDecoration: "none" }} to={`/recetas/${slug}`}>
          <p>{description.substring(0, 130)}...</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to={`/recetas/${slug}`}>
          <button style={{marginTop: 20}} className="btn asdlfkj9asdasdxw2">{buttonTitle}</button>
        </Link>
      </div>
    </div>
  );
};
