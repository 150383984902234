import React from "react";
import { Link } from "react-router-dom";

export default ({ img, title, description, hash, readtime, slug }) => {
  return (
    <div className="asdjasio3943jqasd">
      <div className="d-flex justify-content-between">
        <div className="asjdiow92e">
          <br />
          <Link
            style={{ textDecoration: "none" }}
            to={`/cultura-y-quesos/${slug}`}
          >
            <h2 className="">{title}</h2>
          </Link>
          <span className="Sad3i49sjdfsd">{readtime} minutos de lectura</span>
        </div>
        <div className="img-alig">
          <div class="frame-wrapper">
            <Link
              style={{ textDecoration: "none" }}
              to={`/cultura-y-quesos/${slug}`}
            >
              <img src={img} alt="" className="pic-art" />
            </Link>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "left" }}>
        <p className="margin-null ">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/cultura-y-quesos/${slug}`}
          >
            {description.substring(0, 120)}... {hash.id}
          </Link>
        </p>
        <spa>
          {hash.map((x) => (
            <span key={x.tag} style={{ marginRight: 10 }}>
              {x.tag}
            </span>
          ))}
        </spa>
      </div>
    </div>
  );
};
