import { Link, Route, Switch } from "react-router-dom";
import "react-modal-video/scss/modal-video.scss";

import "./App.css";
import Home from "./pages/home";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import Page5 from "./pages/page5";
import Quesos from "./pages/quesos";
import Recetas from "./pages/recetas";
import Cultura from "./pages/cultura";
import Eventos from "./pages/eventos";
import Comprar from "./pages/comprar";
import Terminos from "./pages/terminos";

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/quesos/:slug">
        <Page2 />
      </Route>
      <Route path="/quesos">
        <Quesos />
      </Route>
      <Route path="/recetas/:slug">
        <Page3 />
      </Route>
      <Route path="/recetas">
        <Recetas />
      </Route>
      <Route path="/cultura-y-quesos/:slug">
        <Page4 />
      </Route>
      <Route path="/cultura-y-quesos">
        <Cultura />
      </Route>
      <Route path="/eventos">
        <Eventos />
      </Route>
      <Route path="/evento/:slug">
        <Page5 />
      </Route>
      <Route path="/donde-comprar">
        <Comprar />
      </Route>
      <Route path="/terminos-y-condiciones">
        <Terminos />
      </Route>
    </Switch>
  );
}

export default App;
