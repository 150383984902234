import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import img11 from "../images/img11.png";
import NuestrosCard from "../components/NuestrosQuesosCard";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import img22 from "../images/img22.png";
import Enalianzaconbox from "../components/Enalianzaconbox";
import Footer from "../components/footer";
import NavHome from "../components/navHome";
import ModalVideo from "react-modal-video";
import { Helmet } from "react-helmet";
import SideButtons from "../components/sideButtons";
import { Fade } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";

import ig1 from "../images/ig_vm/1.png";
import ig2 from "../images/ig_vm/2.jpg";
import ig3 from "../images/ig_vm/3.jpg";
import ig4 from "../images/ig_vm/4.jpg";
import vid2 from "../images/ig_vm/vid2.png";

function App() {
  const [data, setData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isfixed, setrisfixed] = useState(false);
  const [events, setEvents] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [recipeCover, setRecipeCover] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const url = "http://localhost:3000";

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 40) {
      setrisfixed(true);
    } else {
      setrisfixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    const fetchEventos = async () => {
      const result = await axios(url_base + "/eventos");
      setEvents(result.data);
    };

    const fetchData = async () => {
      const result = await axios(url_base + "/quesos?favoritos=false");
      setData(result.data.sort(() => Math.random() - 0.5));
    };

    const fetchFavorites = async () => {
      const result = await axios(url_base + "/quesos?favorito_andino_ne=false");
      setFavorites(result.data.sort(() => Math.random() - 0.5));
    };

    const fetchArticles = async () => {
      const result = await axios(url_base + "/articulos?visible_ne=Mexico");
      setArticles(result.data.sort(() => Math.random() - 0.5));
    };

    const fetchRecetas = async () => {
      const result = await axios(url_base + "/recetas?quesos_gt=0");
      setRecipeCover(result.data.sort(() => Math.random() - 0.5));
    };

    const fetchIpLocation = async () => {
      const result = await axios("https://ip.nf/me.json");
      console.log(result.data.ip.country);
    };
    fetchIpLocation();
    fetchFavorites();
    fetchRecetas();
    fetchArticles();
    fetchData();
    fetchEventos();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Quesos de Europa</title>
        <meta
          name="description"
          content="¡Somos una comunidad de amantes del queso! Compartimos el conocimiento de los quesos de Europa en Latinoamérica, con apoyo de la Unión Europea."
        />
      </Helmet>
      <div className="asdj9ejdascsnb3a" style={{ marginTop: "-112px" }}>
        <NavHome />
        <div className="slide-container">
          <Fade arrows={false}>
            <div className="each-fade">
              <div className="background-one">
                <div style={{ height: "700px" }}>
                  <h1>
                    Las gastronomías de Francia y Colombia hablan el mismo
                    idioma, el del amor.
                  </h1>
                  <p>-</p>
                </div>
              </div>
            </div>

            <div className="each-fade">
              <div className="background-two">
                <div style={{ height: "700px" }}>
                  <h1>
                    Las gastronomías de Francia y Ecuador hablan el mismo
                    idioma, el del amor.
                  </h1>
                  <p>-</p>
                </div>
              </div>
            </div>

            <div className="each-fade">
              <div className="background-three">
                <div style={{ height: "700px" }}>
                  <h1>
                    Las gastronomías de Francia y Perú hablan el mismo idioma,
                    el del amor.
                  </h1>
                  <p>-</p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="asdfjsdf112c">
          <div>
            <p className="sdfaiso39nncbs">Quesos favoritos</p>
          </div>
        </div>
        <div
          className="d-flex sdfjioe-3wda"
          style={{ justifyContent: "space-between" }}
        >
          {favorites.slice(0, 4).map((x) => (
            <div className="d-flex no-border asdhfie392jsd">
              <div>
                <img className="fav-img" src={url_base + x.imgqueso.url} />
              </div>
              <div className="mr-20px">
                <h1>{x.nombre}</h1>
                <Link to={`/quesos/${x.slug}`}>
                  <a>Ver Queso</a>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="nsdaf93ijsfsj">
        <div className="container">
          <h1 className="resp-head">▸ Nuestros Quesos</h1>
          <p>
            El mundo de los quesos europeos es inmenso y delicioso, conócelos y
            enamórate. Te invitamos a incluirlos en las opciones del día a día y
            usarlos incluso para preparar platillos locales, logrando una nueva
            mezcla de sabores.
          </p>
          <div className="row">
            {data.slice(0, 8).map((x) => (
              <div className="col-md-3 col-6">
                <NuestrosCard
                  img={url_base + x.imgqueso.url}
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
          <div className="">
            <Link to={`/quesos`}>
              <button className="btn asjfioj23adsd m-auto">
                Ver todos los quesos
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="Próximoseventos">
        {/* A JSX comment 
        <div className="container">
          <h1 className="resp-head">▸ Próximos eventos</h1>
          <div className="row">
            {events.map((x) => (
              <div className="col-md-4">
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/cultura-y-quesos`}
                >
                  <div
                    className="asfjidfjasdkoasp3"
                    style={{
                      "--img": `url("${url_base + x.imagen.url}")`,
                    }}
                  >
                    <p>{x.cuando}</p>
                    <h1>{x.nombre}</h1>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      */}
      </section>

      <section className="Recetas">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {" "}
              {recipeCover
                .slice(0, 1)
                .reverse()
                .map((x) => (
                  <img
                    src={url_base + x.imagen.url}
                    className="w-100 recetas-img adsf38sad"
                  />
                ))}
            </div>
            <div className="col-md-7">
              <h1 className="resp-head">▸ Recetas</h1>
              <p>
                El queso es un excelente ingrediente para tus platillos. Prepara
                las mejores recetas con quesos y conoce cómo aprovecharlos al
                máximo.
              </p>
              <Link to="/recetas">
                <button className="btn" style={{ height: 50 }}>
                  Conócelas todos
                </button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mgn-cultura">
                <h1 className="resp-head">▸ Cultura y Queso</h1>
                <p className="">
                  Descubre la historia, datos curiosos y mucha información
                  valiosa para los amantes de los quesos y la gastronomía.
                </p>
              </div>
            </div>
            {articles
              .slice(articles.length - 5, articles.length)
              .reverse()
              .map((x) => {
                if(x.visible !== 'Mexico'){
                  return (
                <div className="col-md-6 mb-6 pb-5">
                  <CulturayQuesoCard
                    slug={x.slug}
                    title={x.titulo}
                    description={x.introduccion}
                    hash={[x.tags]}
                    img={url_base + x.imagen.url}
                    readtime={x.tiempo_lectura}
                  />
                </div>);}
              })}
          </div>
          <div className="align-btn">
            <Link to={`/cultura-y-quesos`}>
              <a className="ver-btn asjfioj23adsd m-auto">Ver más artículos</a>
            </Link>
          </div>
        </div>
      </section>
      <section className="Nuestrasredes">
        <h1 className="resp-head">▸ Nuestras redes</h1>
        <p className="mb-5">
          Síguenos en Instagram{" "}
          <span>
            <a
              style={{ color: "#EB5919", textDecoration: "none" }}
              href="https://www.instagram.com/quesosdeeuropa/"
              target="_blank"
            >
              @QuesosDeEuropa
            </a>
          </span>{" "}
           Y {" "}
          <span>
            <a
              style={{ color: "#EB5919", textDecoration: "none" }}
              href="https://www.facebook.com/QuesosDeEuropaAndino/"
              target="_blank"
            >
              @QuesosDeEuropaAndino
            </a>
          </span>{" "}
          en Facebook 
        </p>

        <div className="container">
          <div className="d-flex justify-content-between asdfsdhu3-asd">
            <div className="row">
              <div className="col-md-2 asb">
                <a
                  href="https://www.instagram.com/p/COyOmHup3dX/"
                  target="_blank"
                >
                  <img
                    src={ig1}
                    className="w-100 image-grid"
                    style={{ height: "11em", objectFit: "cover" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/p/COwIQPuANK9/"
                  target="_blank"
                >
                  <img
                    src={ig2}
                    className="w-100 image-grid"
                    style={{
                      height: "11em",
                      objectFit: "cover",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  />
                </a>
              </div>
              <div className="col-md-4">
                <ModalVideo
                  channel="vimeo"
                  autoplay
                  isOpen={isOpen}
                  videoId="312590823"
                  onClose={() => setOpen(false)}
                />
                <img
                  onClick={() => setOpen(true)}
                  src={img22}
                  className="w-100"
                  style={{
                    height: "23em",
                    objectFit: "cover",
                    marginBottom: "1em",
                  }}
                />
              </div>
              <div className="col-md-2">
                <a
                  href="https://www.instagram.com/p/COq-m8Nj5H7/"
                  target="_blank"
                >
                  <img
                    src={ig3}
                    className="w-100 image-grid"
                    style={{ height: "11em", objectFit: "cover" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/p/COoZ02lpOAI/"
                  target="_blank"
                >
                  <img
                    src={ig4}
                    className="w-100 image-grid"
                    style={{
                      height: "11em",
                      objectFit: "cover",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  />
                </a>
              </div>
              <div className="col-md-4">
                <ModalVideo
                  channel="vimeo"
                  autoplay
                  isOpen={isOpen}
                  videoId="312602541"
                  onClose={() => setOpen(false)}
                />
                <img
                  onClick={() => setOpen(true)}
                  src={vid2}
                  className="w-100"
                  style={{ height: "23em", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enalianzaconbox />
      <Footer />
      <SideButtons />
    </>
  );
}

export default App;
