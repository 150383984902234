import axios from "axios";
import { useState, useEffect } from "react";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import React from "react";
import img26 from "../images/img26.png";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import { culturayQuesoList } from "../utilees/CulturayQuesoList";
import NuestrosCard from "../components/NuestrosQuesosCard";
import { nuestrosList } from "../utilees/nuestrosList";
import Enalianzaconbox from "../components/Enalianzaconbox";
import BreadCrumb from "../components/breadcrum";
import Nav from "../components/navbar";
import Footer from "../components/footer";
import ReactMarkdown from "react-markdown";

function App() {
  const params = useParams();
  const [articles, setArticles] = useState([]);
  const [data, setData] = useState([]);
  const [evento, setEvento] = useState([]);

  const url = "http://localhost:3000";

  console.log(url);

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const result = await axios("https://api.quesosdeeuropa.com/quesos");
      console.log(result.data);
      setData(result.data);
    };
    const fetchEvento = async () => {
      const result = await axios(
        "https://api.quesosdeeuropa.com/eventos?slug=" + params.slug
      );
      console.log(result.data);
      setEvento(result.data);
    };
    const fetchArticles = async () => {
      const result = await axios(url_base + "/articulos");
      console.log(result.data);
      setArticles(result.data);
    };
    fetchArticles();
    fetchEvento();
    fetchData();
  }, []);

  return (
    <>
      <Nav />
      {evento.map((x) => (
        <div>
          <br />
          <br />
          <BreadCrumb
            active={2}
            data={[
              { link: "/", title: "Inicio" },
              { link: "/eventos", title: "Eventos" },
              { link: "", title: x.nombre },
            ]}
          />
          <br />
          <br />
        </div>
      ))}

      <section className="asdn32u8zbzkqw">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="w-100" src={img26} alt="" />
            </div>
            <div className="col-md-6">
              {evento.map((x) => (
                <div>
                  <h1>{x.nombre}</h1>
                  <br />
                  <br />
                  <p className="event-text">
                    <span className="event-title">
                      <b>¿Cuándo?:</b>
                    </span>{" "}
                    {x.cuando}
                  </p>
                  <p>
                    <span>¿Dónde?:</span> {x.donde}
                  </p>
                  <p>
                    <span>Horario:</span> {x.horario}
                  </p>
                  <p>
                    <span>Razones para no perdérselo:</span> {x.razone}
                  </p>
                  <p>
                    <span>Tipo de evento:</span> {x.tipo}
                  </p>
                  <p>
                    <span>Costo:</span> {x.costo}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="sec-blg">
        <div className="container">
          {evento.map((x) => (
            <ReactMarkdown>{x.informacion}</ReactMarkdown>
          ))}
        </div>
      </section>
      <section className="nsdaf93ijsfsj">
        <div className="container">
          <h1> Nuestros Quesos</h1>
          <p>
            El mundo de los quesos europeos es inmenso y delicioso, conócelos y
            enamórate. Te invitamos a incluirlos en las opciones del día a día y
            usarlos incluso para preparar platillos locales, logrando una nueva
            mezcla de sabores.
          </p>
          <div className="row">
            {data.slice(0, 4).map((x) => (
              <div className="col-md-3 col-6">
                <NuestrosCard
                  img={url_base + x.imgqueso.url}
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
          <div className="">
            <Link style={{ textDecoration: "none" }} to={`/quesos`}>
              <button className="btn asjfioj23adsd m-auto" href="/quesos">
                Ver todos los quesos
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="Recetas bg-theme-1">
        <div className="container">
          <h1 className="text-center mb-5">Artículos relacionados</h1>
          <div className="row">
            {articles.slice( articles.length - 4, articles.length).map((x) => (
              <div className="col-md-6 mb-6 pb-5">
                <CulturayQuesoCard
                  slug={x.slug}
                  title={x.titulo}
                  description={x.introduccion}
                  hash={[x.tags]}
                  img={url_base + x.imagen.url}
                  readtime={x.tiempo_lectura}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <div style={{ marginTop: -200 }}>
        <Enalianzaconbox />
        <Footer />
      </div>
    </>
  );
}

export default App;
