import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from "validator";

import Enalianzaconbox from "../components/Enalianzaconbox";
import Nav from "../components/navbar";
import BreadCrumb from "../components/breadcrum";
import CulturayQuesoCard from "../components/CulturayQuesoCard";
import Footer from "../components/footer";

import Sticky from "react-sticky-el";
import ig from "../images/IG.svg";
import fb from "../images/fb.svg";
import tw from "../images/tw.svg";
import shr from "../images/shr.svg";
import left from "../images/arwleft.svg";
import right from "../images/arwright.svg";

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function App() {
  const params = useParams();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [start, setStart] = useState(0);
  const [startEnd, setStartEnd] = useState(3);
  const [filter, setFilter] = useState(0);
  const [endStart, setEndStart] = useState(3);
  const [end, setEnd] = useState(7);

  const nextPage = async () => {
    if (currentPage < maxPages) {
      setStart(end);
      setStartEnd(end + 3);
      setEndStart(end + 3);
      setEnd(end + 5);
      setCurrentPage(currentPage + 1);
    }
  };

  const [headerArticle, setHeaderArticle] = useState([]);
  const [articles, setArticles] = useState([]);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [sector, setSector] = useState("");
  const [suscrito, setSuscrito] = useState(false);

  const url_base = "https://api.quesosdeeuropa.com";

  const suscribe = async () => {
    if (validator.isEmail(email) && sector != "") {
      const new_user = {
        user: email,
        sector: sector,
      };
      axios
        .post(url_base + "/newsletters", new_user)
        .then((response) => setSuscrito(true));
    } else {
      alert("Email no valido");
    }
  };
  //https://api.quesosdeeuropa.com/articulos?categoria=
  const filterArticles = async (data) => {
    const result = await axios(url_base + "/articulos?categoria=" + data);
    setArticles(result.data);
    setMaxPages(Math.round(result.data.length / 7));
    setStart(0);
    setStartEnd(3);
    setEndStart(3);
    setEnd(7);
    setCurrentPage(1);

    if (data == "Datos") {
      setFilter(1);
    }
    if (data == "Tips") {
      setFilter(2);
    }
    if (data == "Historia") {
      setFilter(3);
    }
    if (data == "Momentos") {
      setFilter(4);
    }
  };

  const fetchAllArticles = async () => {
    setFilter(0);
    const result = await axios(url_base + "/articulos");
    console.log(result.data);
    setArticles(result.data);
    setMaxPages(Math.round(result.data.length / 7));
    setStart(0);
    setStartEnd(3);
    setEndStart(3);
    setEnd(7);
    setCurrentPage(1);
  };

  const clickTest = async (data) => {
    alert(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const getArticle = async () => {
      const result = await axios(url_base + "/articulos?visible_ne=Mexico");
      setHeaderArticle(result.data.reverse());
    };
    const fetchData = async () => {
      const result = await axios(url_base + "/recetas");
      console.log(result.data);
      setData(result.data);
    };
    const fetchArticles = async () => {
      const result = await axios(url_base + "/articulos?visible_ne=Mexico");
      console.log(result.data);
      setArticles(result.data.reverse());
      setMaxPages(Math.round(result.data.length / 7));
      console.log(Math.round(articles.length / 7));
    };
    fetchArticles();
    fetchData();
    getArticle();
  }, []);

  return (
    <div className="bg-theme-1">
      <Nav active={2} />
      <div>
        <br /> <br />
        <BreadCrumb
          active={1}
          data={[
            { link: "/", title: "Inicio" },
            { link: "", title: "Cultura y Queso" },
          ]}
        />
      </div>

      <section className="bg-theme-1 sdfashu3wznqwv1">
        {headerArticle.slice(0, 1).map((a) => (
          <div className="container">
            <div className="asm9q3nsan20ass">
              <div className="row">
                <div className="col-md-6">
                  <div className="asdq290ejzxzq">
                    <span style={{ marginTop: 0 }}>
                      {a.tiempo_lectura} minutos de lectura
                    </span>
                    <a
                      className="no-underline"
                      href={"cultura-y-quesos/" + a.slug}
                    >
                      <h1 style={{ marginTop: 10 }}>{a.titulo}</h1>
                    </a>
                    <a
                      className="no-underline"
                      href={"cultura-y-quesos/" + a.slug}
                    >
                      <p style={{ marginTop: 20 }}>{a.introduccion}</p>
                    </a>
                    <div class="d-flex justify-content-start asndjuaez1">
                      {a.tags.map((a) => (
                        <p>#{a.tag} &emsp;</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="frame-wrapper1">
                    <a
                      className="no-underline"
                      href={"cultura-y-quesos/" + a.slug}
                    >
                      <img
                        src={url_base + a.imagen.url}
                        alt=""
                        className="img-cultura"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
      <section className="Recetas mt-5 mb-5">
        <div className="container">
          <div className="cultura-menu">
            <ul>
              <li>
                <a
                  onClick={() => fetchAllArticles()}
                  className={filter == "0" ? "cultura-active" : "clicklable"}
                >
                  Todo
                </a>
              </li>
              <li>
                <a
                  className={filter == "1" ? "cultura-active" : "clicklable"}
                  onClick={() => filterArticles("Datos")}
                >
                  Datos curiosos
                </a>
              </li>
              <li>
                <a
                  className={filter == "2" ? "cultura-active" : "clicklable"}
                  onClick={() => filterArticles("Tips")}
                >
                  Tips
                </a>
              </li>
              <li>
                <a
                  className={filter == "3" ? "cultura-active" : "clicklable"}
                  onClick={() => filterArticles("Historia")}
                >
                  Historia y Origen
                </a>
              </li>
              <li>
                <a
                  className={filter == "4" ? "cultura-active" : "clicklable"}
                  onClick={() => filterArticles("Momentos")}
                >
                  Momentos de consumo
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="Recetas mt-5 mb-5">
        <div className="container">
          <div className="row">
            {articles.slice(start, startEnd).map((x) => (
              <div className="col-md-6 mb-3 pb-3">
                <CulturayQuesoCard
                  title={x.titulo}
                  description={x.introduccion}
                  hash={[x.tags]}
                  img={url_base + x.imagen.url}
                  readtime={x.tiempo_lectura}
                  slug={x.slug}
                />
              </div>
            ))}
            <div className="col-md-6 mb-3 pb-3">
              {suscrito != true && (
                <div className="suscribe-news">
                  <div className="suscribe-news-header">
                    Mantente informado sobre las notas recientes
                  </div>
                  <div className="suscribe-news-input">
                    <input
                      placeholder="Correo electrónico"
                      className="asfjsi3ie9asmbe form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <select
                      className="asfjsi3ie9asmbe form-control"
                      placeholder="Selector"
                      style={{ color: "gray" }}
                      onChange={(e) => setSector(e.target.value)}
                    >
                      <option value="">Sector</option>
                      <option value="" style={{ color: "gray" }}>
                        Sector
                      </option>
                      <option value="Productor" style={{ color: "gray" }}>
                        Productor
                      </option>
                      <option
                        value="Importador/Exportador"
                        style={{ color: "gray" }}
                      >
                        Importador/Exportador
                      </option>
                      <option value="Distribuidor" style={{ color: "gray" }}>
                        Distribuidor
                      </option>
                      <option value="Chef" style={{ color: "gray" }}>
                        Chef
                      </option>
                      <option
                        value="Estudiante de cocina"
                        style={{ color: "gray" }}
                      >
                        Estudiante de cocina
                      </option>
                      <option
                        value="Amante de los quesos"
                        style={{ color: "gray" }}
                      >
                        Amante de los quesos
                      </option>{" "}
                    </select>
                    <button onClick={() => suscribe()} className="btn">
                      Enviar
                    </button>
                  </div>
                </div>
              )}
              {suscrito != false && (
                <div className="suscribe-news">
                  <div className="suscribe-news-header">
                    {email} te has suscrito al newsletter de Quesos de Europa
                  </div>
                </div>
              )}
            </div>
            {articles.slice(endStart, end).map((x) => (
              <div className="col-md-6 mb-3 pb-3">
                <CulturayQuesoCard
                  title={x.titulo}
                  description={x.introduccion}
                  hash={[x.tags]}
                  img={url_base + x.imagen.url}
                  readtime={x.tiempo_lectura}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div style={{ textAlign: "center" }}>
                  <Link onClick={() => nextPage()}>
                    <button className="btn buton-recipes  m-auto">
                      Siguiente
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-md-4" style={{ textAlign: "right" }}>
                <div className="d-flex next-container">
                  <div
                    style={{
                      display: "block ruby",
                    }}
                  >
                    <Link onClick={() => nextPage()}>
                      <button
                        style={{ height: 40, width: 40 }}
                        className="btn asjfioj23adsd m-auto"
                      >
                        <img src={left}></img>
                      </button>
                    </Link>
                    <input
                      onChange={(e) => nextPage()}
                      value={currentPage}
                      placeholder="1"
                      style={{
                        width: 40,
                        height: 40,
                        textAlign: "center",
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    />
                    <Link onClick={() => nextPage()}>
                      <button
                        style={{ height: 40, width: 40 }}
                        className="btn asjfioj23adsd m-auto"
                      >
                        <img src={right}></img>
                      </button>
                    </Link>
                    <div style={{ marginLeft: "3%" }}>de {maxPages}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enalianzaconbox />
      <Footer />
    </div>
  );
}

export default App;
