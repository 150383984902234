import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";

export default ({ img, title, time, slug }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <a href={"/recetas/" + slug} className="no-underline">
            <img src={img} className="img-receta-sug" />
          </a>
        </div>
        <div className="col-md-6">
          <a href={"/recetas/" + slug} className="no-underline">
            <h3>{title}</h3>
          </a>
          <p>
            <AiOutlineClockCircle />{" "}
            <a href={"/recetas/" + slug} className="no-underline" style={{color: 'black'}}>
              {time}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
