import {
    FaFacebookF,
    FaInstagram,
    FaYoutube
  } from "react-icons/fa";
  import { SiTiktok } from "react-icons/si";
  export default () => {
    return (
      <div className="fixedScrollbtn">
        <p className="sadihn22wsae2ewa">Síguenos:</p>
        <p>
          <a
            href="https://www.instagram.com/quesosdeeuropa/"
            target="_blank"
            style={{ color: "#eb5919" }}
          >
            <FaInstagram />
          </a>
        </p>
        <p>
          <a
            href="https://www.facebook.com/QuesosDeEuropaAndino/"
            target="_blank"
            style={{ color: "#eb5919" }}
          >
            <FaFacebookF />
          </a>
        </p>
        <p>
          <a
            href="https://youtube.com/channel/UCP3Yk9YNRuMBEZ2sS8DYbDg"
            target="_blank"
            style={{ color: "#eb5919" }}
          >
            <FaYoutube />
          </a>
        </p>
      </div>
    );
  };
  