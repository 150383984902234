import React from "react";
import { Link } from "react-router-dom";

export default ({ title, author, hasborder, slug }) => {
  return (
    <div
      className="asdjasio3943jqasd"
      style={{ borderLeft: hasborder ? "1px dashed #A5A5A5" : "none" }}
    >
      <div className="d-flex justify-content-between">
        <div className="asjdiow92e">
          <a
            style={{ textDecoration: "none" }}
            href={`/cultura-y-quesos/${slug}`}
          >
            <h2>{title}</h2>
          </a>
          <Link
            style={{ textDecoration: "none" }}
            to={`/cultura-y-quesos/${slug}`}
          >
            <span className="Sad3i49sjdfsd">{author}...</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
