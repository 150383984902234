import React, { useState, useEffect } from "react";
import logo from "../images/logo.png";
import { useMediaQuery } from "react-responsive";
import SideMenu from "../components/sidebar";
import Sidebar from "react-sidebar";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineMenuFold } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import Sticky from "react-sticky-el";

export default () => {
  const [isfixed, setrisfixed] = useState(false);
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });
  const [sidebarOpen, setsidebarOpen] = React.useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 40) {
      setrisfixed(true);
    } else {
      setrisfixed(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Sticky stickyClassName={"stickyBar"}>
      <>
        {!isTablet ? (
          <div>
            <div className={`${isfixed ? "scrolasdfji3waf" : ""}`}>
              <div className="d-flex justify-content-center"></div>
              <div
                className="row"
                className={`row ${isfixed ? "asjdiow92e1" : ""}`}
              >
                <div className="col-md-2"></div>
                <div
                  className="col-md-8 justify-content-center"
                  style={{ textAlign: "center" }}
                >
                  <div
                    className="d-flex justify-content-center asniw3eadas"
                    style={{ textAlign: "center" }}
                  >
                    <div className="sandfi3k9ujscas">
                      <img className="asd38sd-logo" src={logo} style={{marginRight: 50}}/>
                      <a href="/quesos">Quesos</a>
                      <a href="/cultura-y-quesos">Cultura y queso</a>
                      <a href="/recetas"> Recetas</a>
                      <a href="/eventos">eventos</a>
                      <a href="/donde-comprar">¿dónde comprar?</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`${
                isfixed ? "scrolasdfji3waf asdfhisdl pb-3" : "asdfhisdl"
              }`}
            >
              <div
                className="d-flex justify-content-between align-items-center asd93jeaf3scxz container"
                style={{ maxHeight: "50%" }}
              >
                <GiHamburgerMenu
                  onClick={() => setsidebarOpen(true)}
                  style={{ cursor: "pointer" }}
                  fontSize={20}
                  color="white"
                />
                <a href="/">
                  <img className="asd38sd-logo" src={logo} />
                </a>
                <a></a>
              </div>
            </div>
          </>
        )}

        {!isTablet ? (
          <></>
        ) : (
          <>
            <Sidebar
              sidebar={
                <SideMenu
                  handleclose={() => {
                    setsidebarOpen(false);
                  }}
                />
              }
              open={isTablet && sidebarOpen}
              onSetOpen={() => {
                setsidebarOpen(false);
              }}
              styles={{
                sidebar: {
                  background: "black",
                  position: "fixed",
                  backdropFilter: "blur(2px)",
                  maxHeight: "90%",
                },
              }}
              pullRight={false}
              overlayClassName="asda920-sadm223"
            ></Sidebar>
          </>
        )}
      </>
    </Sticky>
  );
};
