import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import React from "react";
import {
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

import CulturayQuesoCard from "../components/CulturayQuesoCard";
import Recetassugeridas from "../components/Recetassugeridas";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import CheckBox from "../components/checkBox";
import Enalianzaconbox from "../components/Enalianzaconbox";
import Nav from "../components/navbar";
import BreadCrumb from "../components/breadcrum";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

function App() {
  const params = useParams();

  console.log(params.slug);

  const [recipe, setRecipe] = useState([]);
  const [articles, setArticles] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [shareUrl, setShareUrl] = useState("");
  const [title, setTitle] = useState("");

  const [data, setData] = useState([]);
  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  useEffect(() => {
    window.scrollTo(0, 0);
    setShareUrl(window.location.href);
    const getCheese = async () => {
      const result = await axios(
        "https://api.quesosdeeuropa.com/recetas?slug=" + params.slug
      );
      console.log(result.data[0]);
      setTitle(result.data[0]["nombre"]);
      setRecipe(result.data);
    };
    const fetchData = async () => {
      const result = await axios(url_base + "/quesos");
      //console.log(result.data);
      //setData(result.data);
    };
    const fetchArticles = async () => {
      const result = await axios(url_base + "/articulos");
      console.log(result.data);
      setArticles(result.data);
    };

    const fetchRecipes = async () => {
      const result = await axios(url_base + "/recetas");
      console.log(result.data);
      setTitle(result.data[0]["nombre"]);
      setRecipes(result.data.sort(() => Math.random() - 0.5));
    };
    fetchRecipes();
    fetchArticles();
    fetchData();
    getCheese();
  }, []);

  return (
    <div className="bg-theme-1">
      <div>
        {recipe.map((x) => (
          <Helmet>
            <title>{x.nombre}</title>
            <meta
              name="description"
              content={x.descripcion.substring(0, 100)}
            />
          </Helmet>
        ))}
      </div>

      <Nav />
      {recipe.map((x) => (
        <div>
          <br />
          <br />
          <BreadCrumb
            active={2}
            data={[
              { link: "/", title: "Inicio" },
              { link: "/recetas", title: "Recetas" },
              {
                link: "",
                title: x.nombre,
              },
            ]}
          />
          <br />
          <br />
        </div>
      ))}

      {recipe.map((x) => (
        <section className="asdn83nzs39sd ">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img src={url_base + x.imagen.url} className="img-receta" />
                <div className="asdm9q3enzc3"></div>
                <p>{x.descripcion}</p>
                <br />
              </div>

              <div className="col-md-6">
                <div>
                  <h1>{x.nombre}</h1>
                  <div className="d-flex">
                    <div>
                      <p>
                        <AiOutlineClockCircle color="#EB5919" /> {x.preparacion}{" "}
                        min
                      </p>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <p>
                        <FiUser color="#EB5919" /> {x.personas} porciones
                      </p>
                      <br />
                    </div>
                  </div>
                  <FacebookShareButton url={shareUrl} quote={title}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    style={{ marginLeft: 10 }}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    style={{ marginLeft: 10 }}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
          <div className="container asdf23iasd121">
            <div className="row">
              <div className="col-md-8 bg-white ">
                <div className="sjklasdfjalas2">
                  <h2>Ingredientes:</h2>
                  {x.Ingredientes.map((x) => (
                    <div>
                      <h2>{x.cosa_a_preparar}</h2>
                      {x.ingredient.map((x) => (
                        <CheckBox title={x.ingrediente} />
                      ))}
                    </div>
                  ))}

                  <h2>Preparación</h2>
                  <h2 className="sad23uzbxcbwza"></h2>
                  <div>
                    {x.Paso.map((x) => (
                      <CheckBox title={x.accion} />
                    ))}
                  </div>
                  <div className="d-flex">
                    <p className="asdn83ihasf2sds">#Historia</p>
                    <p className="asdn83ihasf2sds">#Recetas</p>
                    <p className="asdn83ihasf2sds">#Delicioso</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="asndd83hasd bg-white">
                  <h2>Recetas sugeridas</h2>
                  {recipes
                    .slice(recipes.length - 3, recipes.length)
                    .map((x) => (
                      <Recetassugeridas
                        img={url_base + x.imagen.url}
                        title={x.nombre}
                        slug={x.slug}
                        time={x.preparacion + " minutos"}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}

      <section className="Recetas mt-5 mb-5 bg-theme-1">
        <div className="container">
          <h1 className="text-center mb-5">Artículos relacionados</h1>

          <div className="row">
            {articles.slice(articles.length - 4, articles.length).map((x) => (
              <div className="col-md-6 mb-5 pb-5">
                <CulturayQuesoCard
                  slug={x.slug}
                  title={x.titulo}
                  description={x.introduccion}
                  hash={[x.tags]}
                  img={url_base + x.imagen.url}
                  readtime={x.tiempo_lectura}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <div style={{ marginTop: -100 }}>
        <Enalianzaconbox />
      </div>
      <Footer />
    </div>
  );
}

export default App;
