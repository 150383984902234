import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Recetassugeridas from "../components/Recetassugeridas";
import img34 from "../images/img34.png";
import RecetasconestequesoCard from "../components/RecetasconestequesoCard";
import YouMightLikeCard from "../components/youmightlikeCard";
import Enalianzaconbox from "../components/Enalianzaconbox";
import Nav from "../components/navbar";
import BreadCrumb from "../components/breadcrum";
import Footer from "../components/footer";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

function App() {
  const params = useParams();

  console.log(params.slug);
  const [article, setArticle] = useState([]);
  const [articles, setArticles] = useState([]);
  const [data, setData] = useState([]);
  const [shareUrl, setShareUrl] = useState("");

  const MAX_LENGTH = 250;
  const url_base = "https://api.quesosdeeuropa.com";

  useEffect(() => {
    window.scrollTo(0, 0);
    setShareUrl(window.location.href);

    const getArticle = async () => {
      const result = await axios(
        "https://api.quesosdeeuropa.com/articulos?slug=" + params.slug
      );
      console.log(result.data[0]);
      setArticle(result.data);
    };
    const fetchData = async () => {
      const result = await axios(url_base + "/recetas");
      console.log(result.data);
      setData(result.data);
    };
    const fetchArticles = async () => {
      const result = await axios(url_base + "/articulos");
      console.log(result.data);
      setArticles(result.data);
    };
    fetchArticles();
    fetchData();
    getArticle();
  }, []);

  return (
    <div className="bg-theme-1">
      <div>
        {article.map((x) => (
          <Helmet>
            {/* General tags */}
            <title>{x.titulo}</title>
            <meta name="description" content={x.introduccion} />
            <meta name="image" content={url_base + x.imagen.url} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={x.titulo} />
            <meta property="og:description" content={x.introduccion} />
            <meta property="og:image" content={url_base + x.imagen.url} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={x.introduccion} />
            <meta name="twitter:title" content={x.title} />
            <meta name="twitter:description" content={x.introduccion} />
            <meta name="twitter:image" content={url_base + x.imagen.url} />
          </Helmet>
        ))}
      </div>

      <Nav />

      <div>
        {article.map((x) => (
          <div>
            <br />
            <br />
            <BreadCrumb
              active={2}
              data={[
                { link: "/", title: "Inicio" },
                { link: "/cultura-y-quesos", title: "Cultura y Queso" },
                { link: "", title: x.titulo },
              ]}
            />
          </div>
        ))}
      </div>

      <section className="bg-theme-1 sdfashu3wznqwv1">
        {article.map((x) => (
          <div className="container">
            <div className="asm9q3nsan20ass">
              <div className="row">
                <div className="col-md-6">
                  <div className="asdq290ejzxzq">
                    <span style={{ marginTop: 0 }}>
                      {x.tiempo_lectura} minutos de lectura
                    </span>
                    <h1 style={{ marginTop: 10 }}>{x.titulo}</h1>
                    <p style={{ marginTop: 20 }}>{x.introduccion}</p>
                    <div class="d-flex justify-content-start asndjuaez1">
                      {x.tags.map((x) => (
                        <p>#{x.tag} &emsp;</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="frame-wrapper1">
                    <img
                      src={url_base + x.imagen.url}
                      alt={x.titulo}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        ))}
      </section>
      <section className="asdj83yhasd ">
        <div class="container">
          <br />
          <br />
          <br />
          {article.map((x) => (
            <div class="content-article">{ReactHtmlParser(x.contenido)}</div>
          ))}
          <br />
          <br />
        </div>
      </section>
      <section className="asd82hasd0asz">
        <div className="container">
          <h1>Recetas relacionadas</h1>
          <br />
          <div className="row">
            {data.slice(0, 3).map((x) => (
              <div className="col-md-4">
                <RecetasconestequesoCard
                  img={url_base + x.imagen.url}
                  buttonTitle="Continuar leyendo"
                  title={x.nombre}
                  description={x.descripcion}
                  slug={x.slug}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="asdm3inszzasq9">
        <div className="container">
          <div className="asdfjsdf112c1">
            <div>
              <p
                className="sdfaiso39nncbs"
                style={{ backgroundColor: "#000", color: "white" }}
              >
                También podría gustarrte
              </p>
            </div>
          </div>
          <div className="row">
            {articles.slice(0, 4).map((x) => (
              <div className="col-md-3">
                <YouMightLikeCard
                  slug={x.slug}
                  hasborder={true}
                  title={x.titulo}
                  author={x.introduccion.substring(0, 50)}
                />
              </div>
            ))}
          </div>
          {/* 
          <div className="asdfsidhr38snxqw">
            <div className="row ">
              <div className="col-md-6">
                <div
                  className="d-flex asjdwjqi8sn2"
                  style={{ float: "left", marginLeft: "10%" }}
                >
                  <div>
                    <div class="frame-wrapper">
                      <img src={img34} alt="" className="asc12w8ash" />
                    </div>
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <p>Siguiente Artículo</p>
                    <h2 className="zmxzxqw8y">Now we're getting somewhere</h2>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div
                  className="d-flex asjdwjqi8sn2"
                  style={{ float: "right", marginRight: "10%" }}
                >
                  <div style={{ marginLeft: 30 }}>
                    <p>Artículo Anterior</p>
                    <h2 className="zmxzxqw8y">Artículo Anterior</h2>
                  </div>
                  <div>
                    <div class="frame-wrapper">
                      <img src={img34} alt="" className="asc12w8ash" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        */}</div>
        <br />
        <br />
      </section>
      <Enalianzaconbox />
      <Footer />
    </div>
  );
}

export default App;
