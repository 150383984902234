import React from "react";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

export default ({ img, title, description, slug }) => {
  return (
    <div className="sadfjkeui34bxzc" style={{marginTop:30, marginBottom:30}}>
      <Link style={{ textDecoration: "none" }} to={`/quesos/${slug}`}>
        <img src={img} />
      </Link>

      <Link style={{ textDecoration: "none" }} to={`/quesos/${slug}`}>
        <h1> {title}</h1>
      </Link>
      <Link style={{ textDecoration: "none" }} to={`/quesos/${slug}`}>
        <p>{description.substring(0, 120)}...</p>
      </Link>
    </div>
  );
};
